import React from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
  setFeed,
  setFeedType,
  initiateFeed,
  setFeedTabKey,
} from "../../../../../redux/feed/feed.actions";
import moment from "moment";

import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip";
import CopyFeed from "./CopyFeed";
import {MAIN_ROUTES} from "../../../../../constants/routes";
import CountAssetsInFilter from "../../../../commons/CountAssetsInFilter/CountAssetsInFilter";
import {FaEdit, FaRegCopy} from "react-icons/fa";
import Icon from "../../../../commons/Icon";

const FeedsListCard = ({feed}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelectFeed = () => {
    dispatch(initiateFeed());
    dispatch(setFeed(feed));
    dispatch(setFeedType(feed.feedType));
    dispatch(setFeedTabKey("assets"));
    history.push(MAIN_ROUTES.feedsEditFeed.path);
  };

  return (
    <tr>
      <td
        style={{width: "300px"}}
        className="align-middle icon-btn"
        onClick={() => handleSelectFeed()}
      >
        <ReelerTooltip text="Show feed">{feed?.feedName}</ReelerTooltip>
      </td>
      <td style={{width: "100px", textTransform: "capitalize"}}>
        {feed?.feedType ? feed?.feedType : "web"}
      </td>
      <td style={{width: "100px", textTransform: "capitalize"}}>
        {feed?.feedLayout}
      </td>
      <td style={{maxWidth: "100px"}}>
        {feed?.createdAt &&
          moment(new Date(feed?.createdAt?.seconds * 1000)).format(
            "YYYY-MM-DD"
          )}
      </td>

      <td className="text-center">
        <div className="d-flex flex-row justify-content-end algign-items-center">
          <div className="mx-2">
            <CountAssetsInFilter
              filter={
                feed?.tags && Object.keys(feed?.tags)?.length > 0
                  ? {tags: Object.keys(feed?.tags)}
                  : null
              }
            />
          </div>
          <div className="mx-3">
            <Icon onClick={() => handleSelectFeed()} tooltip="Edit feed">
              <FaEdit className="icon-color" />
            </Icon>
          </div>

          <CopyFeed
            feed={feed}
            btn={
              <Icon tooltip="Duplicate feed">
                <FaRegCopy style={{strokeWidth: "3"}} className="icon-color" />
              </Icon>
            }
          />
        </div>
      </td>
    </tr>
  );
};

export default FeedsListCard;
