import React, {useEffect} from "react";
import * as S from "./FeedModal.styles";
import {FaInstagram} from "react-icons/fa";
import {IoIosClose} from "react-icons/io";
import MediaType from "../MediaType";
import {CAMPAIGN_TYPE, IMG_SIZES} from "../../constants";

export default function FeedModal({isOpen, onClose, asset, feed}) {
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  const handleClickProductButton = (product) => {
    if (product?.url) {
      window.open(product.url, "_blank", "noreferrer");
    }
  };

  return (
    <S.ModalContainer showModal={isOpen} onClick={onClose}>
      <S.ModalWrapper>
        <S.Modal>
          <S.ModalClose onClick={onClose}>
            <IoIosClose size="2rem" />
          </S.ModalClose>

          <S.ModalContent>
            <S.ModalColumn>
              <S.MediaContainer>
                <MediaType
                  asset={asset}
                  imgSize={IMG_SIZES.preView}
                  objectFit="cover"
                  videoSettings={{
                    autoplay: "autoplay",
                    muted: true,
                    loop: true,
                    controls: true,
                    playsinline: true,
                  }}
                />
              </S.MediaContainer>
            </S.ModalColumn>
            {feed.feedSettings?.showCaptionInModal ||
            feed.feedSettings?.showProductInfoInModal ? (
              <S.ModalColumn>
                <S.ModalHeader>
                  {[
                    CAMPAIGN_TYPE.igHashtag.type,
                    CAMPAIGN_TYPE.igMentions.type,
                  ].includes(asset?.campaign_type) ? (
                    <S.SocialMediaIcon>
                      <FaInstagram size="1.5rem" />
                    </S.SocialMediaIcon>
                  ) : null}
                  {feed.feedSettings?.showUsernameInModal ? (
                    <S.ModalHeaderTitle>{asset?.username}</S.ModalHeaderTitle>
                  ) : null}
                </S.ModalHeader>
                <S.ModalBody>
                  {feed.feedSettings?.showCaptionInModal && (
                    <S.ContentCaption>
                      {!feed.feedSettings?.showCaptionType ||
                      feed.feedSettings?.showCaptionType === "CREATOR"
                        ? asset?.caption
                        : asset?.edited_caption}
                    </S.ContentCaption>
                  )}
                  {feed.feedSettings?.showProductInfoInModal ? (
                    <>
                      <S.ProductsContainer>
                        {asset?.products?.length > 0 &&
                        feed.feedSettings?.productHeading ? (
                          <S.ProductHeadingContainer>
                            <S.ProductHeading>
                              {feed.feedSettings?.productHeading}
                            </S.ProductHeading>
                          </S.ProductHeadingContainer>
                        ) : null}

                        {asset?.products?.map((p) => (
                          <S.ProductContainer
                            onClick={() => handleClickProductButton(p)}
                          >
                            {p?.imageUrl ? (
                              <S.ProductImageContainer>
                                <S.ProductImage src={p?.imageUrl} />
                              </S.ProductImageContainer>
                            ) : null}
                            <S.ProductInfoContainer>
                              <div>
                                {p?.name && (
                                  <S.ProductName>{p.name}</S.ProductName>
                                )}
                                {feed.feedSettings
                                  ?.showProductDescriptionInModal &&
                                  p?.description && (
                                    <S.ProductDescription>
                                      {p.description}
                                    </S.ProductDescription>
                                  )}
                              </div>
                              {p?.url &&
                              feed.feedSettings?.productButtonText ? (
                                <S.ProductButtonContainer>
                                  <S.ProductButton
                                    href={p?.url}
                                    target="_blank"
                                  >
                                    {feed.feedSettings?.productButtonText}
                                  </S.ProductButton>
                                </S.ProductButtonContainer>
                              ) : null}
                            </S.ProductInfoContainer>
                          </S.ProductContainer>
                        ))}
                      </S.ProductsContainer>
                    </>
                  ) : null}
                </S.ModalBody>
              </S.ModalColumn>
            ) : null}
          </S.ModalContent>
        </S.Modal>
        {!feed?.removePoweredBy ? (
          <S.ModalPoweredBy>
            <S.ModalPoweredByText>Powered by</S.ModalPoweredByText>

            <a
              href="https://www.reelertech.com"
              target="_blank"
              style={{display: "flex", alignItems: "center"}}
            >
              <S.ModalPoweredByLogo
                src={`${process.env.REACT_APP_WIDGET_URL}/assets/images/Reeler-white-transparent-90x21.png`}
                alt="Reeler logo"
              />
            </a>
          </S.ModalPoweredBy>
        ) : null}
      </S.ModalWrapper>
    </S.ModalContainer>
  );
}
