import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAccounts} from "../../redux/account/account.selectors";
import Tag from "../commons/Tag";
import {Form, InputGroup} from "react-bootstrap";
import ReelerButton from "../commons/ReelerButton";
import {updateAccounts} from "../../redux/account/account.actions";
import {database} from "../../firebase";
import useUserFeedbackMessage from "../../hooks/useUserFeedbackMessage";

export default function ManageCreatorLists() {
  const [loading, setLoading] = useState(false);
  const account = useSelector(selectAccounts);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const {setSuccessMessage, setErrorMessage} = useUserFeedbackMessage();

  const handleDeleteList = (list) => {
    const newList = account?.creatorLists.filter((l) => l !== list);

    database.accounts
      .doc(account.id)
      .update({creatorLists: newList})
      .then(() => {
        dispatch(updateAccounts({...account, creatorLists: newList}));

        setSuccessMessage("Creator list deleted successfully");
      })
      .catch((err) => {
        setErrorMessage("There was an error deleting your creator list");
      });
  };

  const handleAddList = () => {
    const list = inputRef.current.value;

    database.accounts
      .doc(account.id)
      .update({creatorLists: list})
      .then(() => {
        dispatch(
          updateAccounts({
            ...account,
            creatorLists: [...account.creatorLists, list],
          })
        );
        inputRef.current.value = "";
        setSuccessMessage("Creator list added successfully");
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage("There was an error adding your creator list");
        setLoading(false);
      });
  };

  return (
    <div>
      <h4 className="mt-5">Manage creator lists</h4>

      <InputGroup className="mb-3" style={{maxWidth: "300px"}}>
        <Form.Control
          ref={inputRef}
          type="text"
          placeholder="Add creator list"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddList();
            }
          }}
        />

        <ReelerButton
          dispatch={handleAddList}
          text="Add"
          loading={loading}
          styleClass="btn-secondary"
        />
      </InputGroup>

      <Form.Text muted>
        Add new creator lists by typing the name and pressing "Add". Delete a
        creator list by clicking on the list below.
      </Form.Text>
      <div className="d-flex flex-wrap mt-3">
        {account &&
          account?.creatorLists
            ?.sort((a, b) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
            ?.map((list, index) => (
              <Tag key={index} onClick={() => handleDeleteList(list)}>
                {list}
              </Tag>
            ))}
      </div>
    </div>
  );
}
