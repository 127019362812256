import React from "react";
import {Form, Row, Col} from "react-bootstrap";

import {useDispatch, useSelector} from "react-redux";
import {selectFeed} from "../../../../../../redux/feed/feed.selectors";
import {updateFeed} from "../../../../../../redux/feed/feed.actions";
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup";

export default function CardSettings() {
  const feed = useSelector(selectFeed);
  const dispatch = useDispatch();

  const handleChange = ({target: {name, value, type}}) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: {...feed.feedSettings, [name]: Number(value)},
        })
      );
    } else {
      dispatch(
        updateFeed({feedSettings: {...feed.feedSettings, [name]: value}})
      );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="border-radius">
            <UnitInputGroup
              label="Border radius"
              name="borderRadius"
              min={0}
              max={50}
              value={
                feed?.feedSettings?.borderRadius
                  ? feed?.feedSettings?.borderRadius
                  : Number()
              }
              dispatch={handleChange}
              unit="px"
            />
            <Form.Text muted>Roundness of corners</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              id="show-social-media-icon"
              name="show-social-media-icon"
              label="Show social media icon on card"
              type="checkbox"
              checked={feed.feedSettings?.socialMediaIcon}
              onChange={() =>
                dispatch(
                  updateFeed({
                    feedSettings: {
                      ...feed.feedSettings,
                      socialMediaIcon: !feed.feedSettings?.socialMediaIcon,
                    },
                  })
                )
              }
            />
          </Form.Group>
          {false && feed.feedSettings?.socialMediaIcon ? (
            <div className="ms-3">
              <Form.Group>
                <Form.Check
                  id="link-social-media-icon-to-post"
                  name="link-social-media-icon-to-post"
                  type="checkbox"
                  label="Link social media icon to post"
                  checked={feed.feedSettings?.linkSocialMediaIconToPost}
                  onChange={() =>
                    dispatch(
                      updateFeed({
                        feedSettings: {
                          ...feed.feedSettings,
                          linkSocialMediaIconToPost:
                            !feed.feedSettings?.linkSocialMediaIconToPost,
                        },
                      })
                    )
                  }
                />
              </Form.Group>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              id="show-popup"
              name="show-popup"
              type="checkbox"
              label="Open popup window"
              checked={feed.feedSettings?.showFeedModal}
              onChange={() =>
                dispatch(
                  updateFeed({
                    feedSettings: {
                      ...feed.feedSettings,
                      showFeedModal: !feed.feedSettings?.showFeedModal,
                      showProductInfoInModal: false,
                      linkToProductUrl: false,
                    },
                  })
                )
              }
            />
          </Form.Group>
          {feed.feedSettings?.showFeedModal ? (
            <div className="ms-3">
              <Form.Group>
                <Form.Check
                  id="show-username-in-modal"
                  name="show-username-in-modal"
                  type="checkbox"
                  label="Show username (when available)"
                  checked={feed.feedSettings?.showUsernameInModal}
                  onChange={() =>
                    dispatch(
                      updateFeed({
                        feedSettings: {
                          ...feed.feedSettings,
                          showUsernameInModal:
                            !feed.feedSettings?.showUsernameInModal,
                        },
                      })
                    )
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  id="show-caption-in-modal"
                  type="checkbox"
                  label="Show caption"
                  checked={feed.feedSettings?.showCaptionInModal}
                  name="show-caption-in-modal"
                  onChange={() =>
                    dispatch(
                      updateFeed({
                        feedSettings: {
                          ...feed.feedSettings,
                          showCaptionInModal:
                            !feed.feedSettings?.showCaptionInModal,
                        },
                      })
                    )
                  }
                />
              </Form.Group>
              {feed.feedSettings?.showCaptionInModal ? (
                <div className="ms-3">
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      inline
                      id="creator-caption"
                      label="Creator caption"
                      onChange={() =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              showCaptionType: "CREATOR",
                            },
                          })
                        )
                      }
                      checked={
                        !feed.feedSettings?.showCaptionType ||
                        feed.feedSettings?.showCaptionType === "CREATOR"
                          ? true
                          : false
                      }
                    />

                    <Form.Check
                      type="radio"
                      inline
                      id="edited-caption"
                      label="Edited Caption"
                      onChange={() =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              showCaptionType: "EDITED",
                            },
                          })
                        )
                      }
                      checked={
                        feed.feedSettings?.showCaptionType === "EDITED"
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                  <Form.Text muted>
                    Choose to display either the imported creator caption or
                    your edited caption.
                  </Form.Text>
                </div>
              ) : null}

              <Form.Group>
                <Form.Check
                  id="show-product-info"
                  name="show-product-info"
                  type="checkbox"
                  label="Show connected products"
                  className="custom-control-input"
                  checked={feed.feedSettings?.showProductInfoInModal}
                  onChange={() =>
                    dispatch(
                      updateFeed({
                        feedSettings: {
                          ...feed.feedSettings,
                          showProductInfoInModal:
                            !feed.feedSettings?.showProductInfoInModal,
                        },
                      })
                    )
                  }
                />
              </Form.Group>
              {feed.feedSettings?.showProductInfoInModal ? (
                <div className="ms-3">
                  <Form.Group controlId="feed-product-heading">
                    <Form.Label>Add an intro heading</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={feed?.feedSettings?.productHeading}
                      onChange={(e) =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              productHeading: e.target.value,
                            },
                          })
                        )
                      }
                    />

                    <Form.Text muted>
                      Add a heading above the product list. If left empypty no
                      heading will be shown.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="feed-product-button-text">
                    <Form.Label>Add a product button text</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={feed?.feedSettings?.productButtonText}
                      onChange={(e) =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              productButtonText: e.target.value,
                            },
                          })
                        )
                      }
                    />

                    <Form.Text muted>
                      Add a product button text. If left empty no button will be
                      shown.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      id="show-product-info-show-description"
                      name="show-product-info-show-description"
                      type="checkbox"
                      label="Show product description"
                      checked={feed.feedSettings?.showProductDescriptionInModal}
                      onChange={() =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              showProductDescriptionInModal:
                                !feed.feedSettings
                                  ?.showProductDescriptionInModal,
                            },
                          })
                        )
                      }
                    />
                  </Form.Group>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* <Form.Group>
            <Form.Check
              id="link-to-product-url"
              name="link-to-product-url"
              type="checkbox"
              label="Link to product url on click"
              checked={feed.feedSettings?.linkToProductUrl}
              disabled={feed.feedSettings?.showFeedModal}
              onChange={() =>
                dispatch(
                  updateFeed({
                    feedSettings: {
                      ...feed.feedSettings,
                      linkToProductUrl: !feed.feedSettings?.linkToProductUrl,
                    },
                  })
                )
              }
            />
          </Form.Group> */}
        </Col>
      </Row>
    </>
  );
}
