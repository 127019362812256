import styled, {css} from "styled-components";

export const ModalContainer = styled.div`
  display: ${({showModal}) => (showModal ? "flex" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1337;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
`;
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  @media (min-width: 800px) {
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

export const Modal = styled.div`
  position: relative;
  background: #fff;
  height: unset;

  @media (min-width: 800px) {
    min-height: 300px;
    max-height: 500px;
    /* min-width: 800px;
    width: calc(100% - 215px); */
    max-width: 1080px;
  }
`;

export const ModalClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background: none;
  border: none;
  color: var(--reeler-black);
  &:hover {
    color: var(--reeler-grey-darker);
  }
  @media (min-width: 800px) {
    position: absolute;
    height: auto;
    width: auto;
    top: 4px;
    right: 0;
    cursor: pointer;
    border: none;

    z-index: 5000;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  @media (min-width: 800px) {
    flex: 1;
  }
`;

export const MediaContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 500px;
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 2rem;
  height: 40px;
  overflow: auto;
`;
export const SocialMediaIcon = styled.span`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ModalHeaderTitle = styled.span`
  margin: 0 0.75rem;
`;
export const ModalBody = styled.div`
  padding: 0.25rem 2rem;
  overflow: auto;
  max-height: 400px;
  margin-bottom: 2rem;
`;
export const ProductHeadingContainer = styled.div`
  display: flex;

  padding: 0 0 0.5rem 0;
  margin: 1.5rem 0 0.5rem 0;
`;
export const ProductHeading = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;
export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  overflow: auto;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    background: var(--reeler-grey);
  }
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
`;
export const ProductName = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;
export const ProductDescription = styled.p`
  font-size: 0.75rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProductButtonContainer = styled.span``;
export const ProductButton = styled.a`
  background: #000;
  color: #fff;
  padding: 0.25rem 1rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #fff;
    background: #878787;
    text-decoration: none;
  }
`;

export const ProductImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-right: 2rem;
`;
export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentCaption = styled.div`
  font-size: 1rem;
`;

export const ModalPoweredBy = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ModalPoweredByText = styled.span`
  margin-right: 0.25rem;
  color: var(--reeler-grey-darker);
  font-size: 0.5rem;
`;

export const ModalPoweredByLogo = styled.img`
  width: 50px;
  object-fit: contain;
`;
